import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Header from "../components/Header/Header";
import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonUnderline from "../components/Button/ButtonUnderline";

const Page = ({ data }) => {
   return (
      <Layout>
         <SearchEngineOptimization
            title="Estate Planning Legal Services | Conscious Legal"
            description="Conscious Legal offers heart-centered San Diego estate planning legal services for families and business owners. Schedule a consultation today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-16 md:pt-24 pb-10 md:pb-12">
            <div className="container">
               <Header headingLevel="h1" heading="Our Services" textAlignment="text-center mx-auto" />
            </div>
         </section>

         <section className="bg-primary-50 py-16 md:py-18">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
                  <div className="md:col-start-1 md:col-span-7">
                     <GatsbyImage image={data.estatePlanning.childImageSharp.gatsbyImageData} alt="Estate Planning" />
                  </div>
                  <div className="md:col-end-13 md:col-span-5">
                     <h2>Estate Planning</h2>
                     <p>
                        Do you know what would happen to everything you have if something unexpected happened to you? Conscious Legal, APC will create
                        a customized estate plan or a “legal life plan” tailored to your needs—a plan that fine-tunes as your personal and financial
                        situations evolve.
                     </p>
                     <ButtonGhost href="/estate-planning-small-business-owners/" text="Learn More" />
                  </div>
               </div>
            </div>
         </section>

         <section className="pt-16 md:pt-20 pb-32 md:pb-48">
            <div className="container">
               <Header headingLevel="h2" heading="Additional Services" textAlignment="text-center mx-auto" textMargin="mb-12 md:mb-18" />
               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10">
                  <div>
                     <GatsbyImage image={data.businessAdvising.childImageSharp.gatsbyImageData} alt="Business Advising" className="mb-7" />
                     <h2>Business Advising</h2>
                     <p>
                        Conscious Legal, APC will help you integrate strategic legal analysis with a holistic, values-driven approach to doing
                        business throughout California. We will be there through every stage as your business develops, and provide continuous
                        guidance and advice so you may achieve your goals.
                     </p>
                     <ButtonUnderline href="/small-business-attorney/" text="Learn More" />
                  </div>

                  <div>
                     <GatsbyImage image={data.carePlanning.childImageSharp.gatsbyImageData} alt="Long Term Care Planning" className="mb-7" />
                     <h2>Long Term Care Planning</h2>
                     <p>
                        Planning for retirement and the possible necessity of long-term care can cause anxiety and stress. Give yourself some peace of
                        mind and allow Conscious Legal, APC to provide understandable information about options, different levels of care, and fee
                        coverage possibilities. Assistance from experienced attorneys is essential to making the right decisions.
                     </p>
                     <ButtonUnderline href="/long-term-care-planning-attorney/" text="Learn More" />
                  </div>
               </div>
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Services_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Services_TW.jpg" }) {
         publicURL
      }
      estatePlanning: file(relativePath: { eq: "services/estate-planning.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessAdvising: file(relativePath: { eq: "services/business-advising.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      carePlanning: file(relativePath: { eq: "services/Long Term Care desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
